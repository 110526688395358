<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'Main'
};
</script>
